import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useEvent } from '@ibox/ui';

import { AddingUserDialogConnected } from '../../../_Profile/UserList/adding-user-dialog/adding-user-dialog';
import { openAddingUserDialog } from '../../../_Profile/UserList/adding-user-dialog/ducks/AddingUserDialog.actions';
import * as blockActions from '../../../BlockUserDialog/ducks/BlockUserDialog.actions';
import { EditingUserRoleView } from '../../../_Profile/UserList/editing-user-role/editing-user-role';
import { putUserRole } from '../../../_Profile/UserList/ducks/UserList.actions';
import * as authSelectors from '../../../../common_components/Auth/ducks/Auth.selectors';
import * as companySelectors from '../../../_Profile/Company/ducks/Company.selectors';

import * as actions from './ducks/Users.actions';
import * as selectors from './ducks/Users.selectors';
import { UsersView } from './Users.view';

type UsersProps = {
  inn: number;
  selectedParticipant: {
    id: number;
  };
};
export const Users = ({ selectedParticipant, inn }: UsersProps) => {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);

  const results = useSelector((state) => selectors.results(state));
  const page = useSelector((state) => selectors.page(state));
  const companyData = useSelector((state) =>
    companySelectors.getCompanyData(state)
  );
  const last = useSelector((state) => selectors.last(state));

  const isAdmin = useSelector((state) => authSelectors.isAdmin(state));
  const userInn = useSelector((state) => authSelectors.getUserInn(state));

  const handleReloadAction = useEvent(() => {
    if (selectedParticipant.id) {
      dispatch(
        actions.getRequest({
          id: selectedParticipant.id,
          page: 0,
        })
      );
    }
  });

  useEffect(() => {
    if (selectedParticipant.id) {
      dispatch(
        actions.getRequest({
          id: selectedParticipant.id,
          page: 0,
        })
      );
    }
    return dispatch(actions.unmounted());
  }, [selectedParticipant.id]);

  const onOpenAddingUserDialog = useEvent(() => {
    dispatch(openAddingUserDialog());
  });

  const onPageChange = () => {
    dispatch(
      actions.getRequest({
        id: selectedParticipant.id,
        page: page + 1,
      })
    );
  };

  const handleBlockDialog = (value) => {
    dispatch(blockActions.openBlockUserDialog(value.user));
  };

  return (
    <>
      <AddingUserDialogConnected
        isRegisterUserUOT
        selectedParticipant={selectedParticipant}
        actionAfterSubmit={handleReloadAction}
      />
      <EditingUserRoleView
        isParticipantUser
        companyID={companyData?.id}
        putUserRole={putUserRole}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <UsersView
        companyId={companyData?.id}
        currentPage={page}
        isAdmin={isAdmin}
        userInn={userInn}
        companyInn={inn}
        last={last}
        openBlockUserDialog={handleBlockDialog}
        results={results}
        setSelectedUser={setSelectedUser}
        onOpenAddingUserDialog={onOpenAddingUserDialog}
        onPageChange={onPageChange}
      />
    </>
  );
};
