import React from 'react';

import { TFunction } from 'i18next';
import { MRT_Cell } from 'material-react-table';
import { format } from 'date-fns';
import { Box } from '@mui/material';

import { userStatusValuesMap } from '../../../_Profile/UserList/user-list.constants';
import { translate } from '../../../Translate/Translate';
import { Status } from '../../../status';

import { ActionPopover } from './ActionPopover';

export const getColumns = ({
  t,
  results,
  openBlockUserDialog,
  setSelectedUser,
  companyId,
  isActionPopover,
}: {
  t: TFunction;
  results: unknown[];
  openBlockUserDialog: (value: unknown) => void;
  setSelectedUser: (value: unknown) => void;
  companyId: number;
  isActionPopover: boolean;
}) => [
  {
    header: t('ФИО пользователя'),
    id: 'fullName',
    accessorKey: 'fullName',
    enableSorting: false,
    enableHiding: false,
  },
  {
    header: t('Email пользователя'),
    id: 'email',
    accessorKey: 'email',
    enableSorting: false,
  },
  {
    header: t('Контактный телефон'),
    id: 'phone',
    enableSorting: false,
    accessorKey: 'phone',
    accessorFn: (row: { phones: string[] }) => {
      return row.phones[0] || '—';
    },
  },
  {
    header: t('Роль пользователя'),
    id: 'role',
    enableSorting: false,
    accessorKey: 'role',
    accessorFn: (row: { roles: (keyof typeof rolesNames)[] }) => {
      return row.roles.map((role, index) => (
        <>
          {rolesNames[role]}
          {row.roles[index + 1] ? ', ' : ''}
        </>
      ));
    },
  },
  {
    header: t('Последняя активность'),
    id: 'lastActivity',
    accessorKey: 'lastActivity',
    enableSorting: false,
    accessorFn: (row: { lastActivity: string }) =>
      row.lastActivity
        ? format(new Date(row.lastActivity as string), 'dd.MM.yyyy hh:mm')
        : '—',
  },
  {
    header: t('Статус пользователя'),
    id: 'status',
    accessorKey: 'status',
    enableSorting: false,
    Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => (
      <Status
        label={userStatusValuesMap[cell.getValue()] || '-'}
        status={cell.getValue()}
      />
    ),
  },
  ...(isActionPopover
    ? [
        {
          header: '',
          accessorKey: 'actions',
          id: 'actions',
          enableSorting: false,
          size: '70',
          Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => (
            <Box
              sx={{
                display: ' flex',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <ActionPopover
                cell={cell}
                companyId={companyId}
                openBlockUserDialog={openBlockUserDialog}
                results={results}
                setSelectedUser={setSelectedUser}
              />
            </Box>
          ),
        },
      ]
    : []),
];

const rolesNames = {
  HEAD_TP: translate('Генеральный директор'),
  READ_ONLY: translate('Только просмотр'),
  ADMIN: translate('Администратор'),
  SUZ: translate('СУЗ'),
  USER: translate('Сотрудник'),
  FULL_ACCESS: translate('Полный доступ'),
  RMT_MODERATOR: translate('Модератор РМТ'),
  RMT_SUPERMODERATOR: translate('Супермодератор РМТ'),
  RMT_USER: translate('Редактор РМТ'),
  RMT_PUBLISHER: translate('Публикатор РМТ'),
  COORDINATOR: translate('Сотрудник с правом подтверждения'),
};
